<template>
  <div class="setting-layout-main">
    <div class="setting-title shareUrl">
      <a :href="currentSoundUrl" @click.prevent="copyText">{{ currentSoundUrl }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SetShare',
  props: {
    currentSound: {
      type: String
    }
  },
  data() {
    return {
      shareUrl: ''
    }
  },
  computed: {
    currentSoundUrl() {
      return `${window.location.origin}/?m=${this.shareUrl}`
    }
  },
  watch: {
    currentSound: {
      handler(val) {
        this.shareUrl = val
      },
      immediate: true
    }
  },
  methods: {
    async copyText() {
      const textToCopy = this.currentSoundUrl;
      this.$SHARE01(3, textToCopy)
      const textarea = document.createElement('textarea');
      textarea.value = textToCopy;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand('copy');
        this.$message.success('文本已复制到剪贴板')
      } catch (err) {
        console.error('无法复制文本: ', err);
        this.$message.error('无法复制文本')
      }
      document.body.removeChild(textarea);
    }
  }
}
</script>

<style lang='less' scoped>
.setting-layout-main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.setting-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.08rem;
  height: 0.95rem;
  text-align: center;
}
.shareUrl {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px dashed rgba(255, 255, 255, 0.2);
  justify-content: center;
  align-items: center;
  width: calc(100% - 0.32rem);
  height: 0.6rem;
  margin: 0.16rem 0.16rem 0.04rem;
  padding: 0.08rem 0.16rem;
  display: flex;
  > a {
    &:hover {
      opacity: 0.8;
    }
    &:active {
      opacity: 0.5;
    }
  }
}
</style>