
export const BASE_URL = 'https://naviapi.needmust.cn';

// 微信第三方登录
/**
 * 微信登录参考地址 https://developers.weixin.qq.com/doc/oplatform/Website_App/WeChat_Login/Wechat_Login.html
 */
// AppID: wx49b168d32aeb6729
const wxlogin = {
  appId: 'wx193c396c14f8780c',
  redirectUrl: location.href, // 回调地址
  state: Math.ceil(Math.random() * 1000), // 随意
  scope: 'snsapi_login' // 用户可授权列表用 , 号隔开
};
const wxurl = `https://open.weixin.qq.com/connect/qrconnect?appid=${wxlogin.appId}&redirect_uri=${wxlogin.redirectUrl}&response_type=code&scope=${wxlogin.scope}&state=${wxlogin.state}#wechat_redirect`;

export const WX_DATA = {
  url: wxurl,
  ...wxlogin
};
