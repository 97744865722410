import axios from 'axios';
import { optionUniqueId } from './helper';
import { GET_TOKEN } from './storage';
import { BASE_URL } from './baseConfig';

export const http = axios.create({
  baseURL: BASE_URL,
  timeout: 5000
});


// 请求拦截
http.interceptors.request.use(
  config => {
    const Headers = {
      'front-url': document.referrer,
      dofrom: 'wn_',
      unique_id: optionUniqueId(),
      // token: GET_TOKEN() || '',
      authorization: GET_TOKEN() || '',
    };
    config.headers = Headers;

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
// 响应拦截
http.interceptors.response.use(
  async function (response) {
    return response.data;
  },
  async function (error) {
    return Promise.reject(error);
  }
);
