import { optionUniqueId } from '@/utils/helper';
import { BASE_URL } from '@/utils/baseConfig';

function LREPORT(type, data) {
  const baseURL = `${BASE_URL}/sound/v1/optionUploadLog/?`;
  // const baseURL = 'http://192.168.0.169:9997/sound/v1/optionUploadLog/?';
  // console.log('----------上报-----------', type);
  // console.log(JSON.stringify(data));

  if (!data || typeof data !== 'object' || Object.keys(data).length === 0) {
    // console.log('上报数据为空');
    return;
  }
  // 使用URLSearchParams构建查询参数
  const params = new URLSearchParams();
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      params.append(key, data[key]);
    }
  }
  params.append('action', `se_${type}`);
  params.append('uniqueId', optionUniqueId());
  params.append('timer', new Date().getTime());

  const url = `${baseURL}${params.toString()}`;
  const img = new Image();
  img.src = url;
  // img.onerror = function (e) {
  //   console.log(e);
  // };
}

/**
 * @description: 页面曝光
 * @param {string} userId 用户登录id,可为空
 */
export function PAGE01(userId) {
  let data = {
    userId
  };
  LREPORT('001', data);
}

/**
 * @description: 搜索框点击曝光
 */
export function SEARCH01() {
  LREPORT('002', { type: 1 });
}

/**
 * @description: 搜索框点击曝光
 * @param {string} keyword 搜索词
 * @param {string} type 1：点击搜索按钮（直接搜）2:点击搜索历史词
 * @param {string} engine 搜索引擎(百度, 必应, 搜狗)
 */
export function SEARCH02(keyword, type, engine) {
  LREPORT('003', { keyword, type, engine });
}

/**
 * @description: 切换搜索引擎
 * @param {string} engine 切换后的搜索引擎
 * @param {string} before 切换前的搜索引擎
 */
export function SEARCH03(engine, before) {
  LREPORT('004', { engine, before });
}

/**
 * @description: 播放状态改变
 * @param {boolean} status 播放状态(true/false)
 * @param {string} music 播放音乐名称(字符串数组)
 */
export function PLAY01(status, music) {
  LREPORT('005', { status, music });
}

/**
 * @description: 音量调整
 * @param {number} volume 音量值
 */
export function VOLUME01(volume) {
  LREPORT('006', { value: volume });
}

/**
 * @description: 跳转链接或协议点击
 * @param {string} name 点击位置
 * @param {string} url 跳转地址
 */
export function LINK01(name, url) {
  LREPORT('007', { name, url });
}

/**
 * @description: 计时器操作
 * @param {string} type '1.打开计时器区域 2.关闭计时器区域 3.开始计时 4.停止计时'
 * @param {number} time 计时时间(设置的计时时间)
 * @param {string} timeType '1.计时结束停止音乐 2.计时结束播放音乐'
 */
export function TIMER01(type, time, timeType) {
  LREPORT('008', { type, time, timeType });
}

/**
 * @description: 组合操作
 * @param {string} type '1.打开组合区域 2.关闭组合区域 3.随机播放 4.点击已保存组合 5.点击保存组合按钮'
 * @param {string} group 保存或播放的组合名称(字符串数组)
 */
export function COMBO01(type, group) {
  LREPORT('009', { type, group });
}

/**
 * @description: 分享操作
 * @param {string} type '1.打开分享区域 2.关闭分享区域 3.点击复制分享链接'
 * @param {string} url 分享的链接
 */
export function SHARE01(type, url) {
  LREPORT('010', { type, url });
}

/**
 * @description: 更多声音操作
 * @param {string} type '1.点击更多声音 2.点击关闭更多声音 3.点击升级会员 4.点击播放更多声音'
 * @param {string} music 试听播放的单个音频名称
 */
export function MORESOUND01(type, music) {
  LREPORT('011', { type, music });
}

/**
 * @description: 登录操作
 * @param {string} type '1.点击页面登录 2.关闭登录弹框 3.微信登录 4.手机号登录'
 */
export function LOGIN01(type) {
  LREPORT('012', { type });
}

/**
 * @description: 我的资料操作
 * @param {string} type '1.点击续费 2.点击退出登录 3.点击关闭我的资料'
 * @param {string} userId 用户id
 */
export function MYINFO01(type, userId = '') {
  LREPORT('013', { type, userId });
}
