<template>
  <div id="app">
    <router-view />
    <div v-if="loginshow">
      <PopupsLogin />
    </div>
    <div v-if="bindshow">
      <OtherLogin class="login_btn" type="wx" />
    </div>
  </div>
</template>

<script>
import OtherLogin from '@/components/other-login/Other-login.vue'
import PopupsLogin from '@/components/popups-login/popups-login.vue'

import { GET_USER_INFO as QUERY_USER } from '@/api/index'

import { SET_TOKEN, SET_USER_INFO, GET_USER_INFO as GET_STORAGE_UINFO, REMOVE_TOKEN, REMOVE_USER_INFO } from '@/utils/storage'
export default {
  name: 'AppPage',
  components: { OtherLogin, PopupsLogin },
  data() {
    return {
      userInfo: {},
      bindshow: false,
      loginshow: false
    }
  },
  watch: {
    '$store.state.loginShow': {
      handler(v) {
        this.loginshow = v
        if (!v) {
          this.$LOGIN01(2)
        }
      }
    },
    '$store.state.bindShow': {
      handler(v) {
        this.bindshow = v
      }
    },
    $route: {
      handler(to) {
        if (to.path.indexOf('/agreement/') > -1) {
          this.$store.commit('setLoginShow', false)
          this.$store.commit('setBindShow', false)
        }
      }
    }
  },
  async created() {
    if (location.search.indexOf('code=') > -1) {
      this.$LOGIN01(3)
      this.bindshow = true
    }
    if (!this.userInfo?.id && GET_STORAGE_UINFO()?.id) {
      this.setuser(GET_STORAGE_UINFO())
    }

    if (this.userInfo?.id) {
      try {
        const info = await QUERY_USER({ user_id: this.userInfo.id })
        if (info.code === 200 && info.data) {
          this.setuser(info.data)
        } else if (info.code === 407) {
          REMOVE_TOKEN()
          REMOVE_USER_INFO()
          this.$store.commit('setUserInfo', {})
        }
      } catch (error) {
        window.console.log(error)
      }
    }
    // 上报曝光
    this.$PAGE01(this.userInfo?.id || '')
  },
  methods: {
    setuser(data) {
      this.userInfo = data
      SET_TOKEN(this.userInfo?.token)
      SET_USER_INFO(JSON.stringify(this.userInfo))
      this.$store.commit('setUserInfo', this.userInfo)
    }
  }
}
</script>

<style lang="less">
#app {
  width: 100%;
  min-width: 7.2rem;
  height: 100vh;
  background-color: rgba(5, 10, 13, 0.8);
  background-image: url("~@/assets/images/bg1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #fff;
  overflow: auto;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
