import Vue from "vue";
import App from "./App.vue";
import './styles/base.less'
import store from './store'
import router from './router'
import '@/plugins/message/index.js'
import reportPlugin from '@/plugins/report/reportPlugin.js' // 上报

Vue.config.productionTip = false;

Vue.use(reportPlugin)

new Vue({
  store,
  router,
  render: (h) => h(App)
}).$mount("#app");
