<template>
  <div class="homeFooter">
    <ul class="agreement">
      <li v-for="(i, x) in navlist" :key="x">
        <!-- <router-link :to="{ name: i.url }">{{
          i.name
        }}</router-link> -->
        <a :href="`/agreement/${i.url}`" @click.prevent="clickLink(i.url, i.name)">{{ i.name }}</a>
      </li>
    </ul>
    <div class="copyright">
      <span>© 2023-2024 学习必备 </span>
      <a
        target="_blank"
        style="display: flex; align-items: center"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030402006159"
      >
        <i></i>
        <!-- <img src="" style="float: left" /> -->
        <span>粤公网安备 44030402006159号</span>
      </a>
      <a href="http://beian.miit.gov.cn/" target="_blank"
        >粤ICP备2021157533号-3</a
      >
      <a href="https://beian.cac.gov.cn/#/home" target="_blank"
        >网信算备440304778258101240015号</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeFooter',
  data () {
    return {
      navlist: [
        { name: '充值协议', url: 'RechargeAgreement' },
        { name: '用户协议', url: 'UserAgreement' },
        { name: '隐私协议', url: 'PrivacyAgreement' },
        { name: '使用条款', url: 'TermsOfUse' },
      ]
    }
  },
  methods: {
    clickLink(url, name) {
      // 链接点击上报
      this.$LINK01(name, `/agreement/${url}`)
      this.$router.push({ name: url })
    }
  }
}
</script>

<style lang='less' scoped>
.homeFooter {
  width: 100%;
  margin: 0 auto;
  font-size: 0.12rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2c2c2c;
  .agreement {
    display: flex;
    justify-content: center;
    > li {
      margin-right: 0.12rem;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: 0.2rem;
    margin-top: 0.14rem;
    > span {
      margin-left: 0.1rem;
      color: #fff;
    }
    > a {
      margin-left: 0.1rem;
      &:hover {
        opacity: 0.8;
      }
      > i {
        display: block;
        width: 0.2rem;
        height: 0.2rem;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("~@/assets/icons/copyright.png");
        background-size: auto 100%;
      }
    }
  }
}
</style>
